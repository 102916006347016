import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

const CwcisPage = () => {

    return (
        <Layout lang={'en'} langURL={'/en/cookies/'}>
            <Seo title="Welcome Teachers!" description="All the information you need to teach your class" lang={'cy'} />
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1>Polisi Cwcis</h1>
                            
                            <h2>Crynodeb</h2>
                            <p>Ffeil destun fach yw cwci sy'n cael ei storio ar eich cyfrifiadur neu ddyfais pan fyddwch yn ymweld â gwefan benodol. Mae'r wefan hon yn defnyddio cwcis er mwyn:</p>
                            <ul>
                                <li>caniatáu i'r wefan hon weithio'n iawn, a helpu i'w chadw'n ddiogel</li>
                                <li>gwneud y wefan hon yn haws i'w defnyddio drwy eich cofio</li>
                                <li>ein helpu i ddeall sut mae pobl yn defnyddio'r wefan hon</li>
                            </ul>
                            <p>Ceir rhagor o wybodaeth am sut mae'r wefan hon yn defnyddio cwcis isod.</p>
                            <h2>Ynglŷn â'r polisi hwn</h2>
                            <p>Mae'r polisi hwn yn esbonio beth yw cwcis, pam ein bod yn eu defnyddio a'r hyn yr ydym yn eu defnyddio ar eu cyfer, ac yn cynnwys manylion y cwcis a ddefnyddir ar y wefan hon. Rydym hefyd wedi darparu gwybodaeth am sut y gallwch reoli cwcis.</p>
                            <p>Rydym yn gobeithio, ar ôl darllen y polisi hwn, y byddwch yn caniatáu i ni barhau i ddefnyddio cwcis i helpu i wneud y gorau o'ch profiad wrth ddefnyddio'r wefan hon.</p>
                            <h2>Beth yw cwci?</h2>
                            <p>Llinynnau byr o destun yw cwcis sy'n cael eu hanfon o weinydd gwe i gof eich porwr gwe pan fyddwch yn ymweld â gwefan benodol. Mae'r cwci'n cael ei storio ar eich cyfrifiadur ond nid yw'n rhaglen y gellir ei weithredu ac ni all niweidio eich caledwedd na'ch meddalwedd.</p>
                            <p>Mae llawer o wahanol fathau o gwcis, gan gynnwys:</p>
                            <p><strong>Cwcis Sesiwn</strong> - Mae’r rhain yn ffeiliau cwcis dros dro sy'n cael eu dileu pan fyddwch yn cau eich porwr rhyngrwyd. Mae hyn yn golygu na fydd y cwcis hyn yn cael eu cofio ar eich ymweliad nesaf.</p>
                            <p><strong>Cwcis Parhaus</strong> - Mae’r rhain yn gwcis sy'n aros yn eich porwr nes i chi naill ai ddewis eu dileu neu maen nhw’n dod i ben. Gall y cwcis hyn fod â dyddiad dod i ben o hyd at 5 mlynedd.</p>
                            <h2>Pam rydyn ni'n defnyddio cwcis?</h2>
                            <p>Mae cwcis yn ein helpu i gasglu gwybodaeth gennych chi, y gallwn ei defnyddio pan fyddwch yn ymweld â'n gwefan neu'n ei defnyddio eto. Fel hyn, gallwn ni wybod lle yr ydych wedi’i gyrraedd yn rhai o'n gemau ar-lein.</p>
                            <p>Mae angen rhai cwcis fel bod y wefan hon yn gweithio'n ddiogel.</p>
                            <p>Ar y wefan hon, rydym yn defnyddio cwcis am y rhesymau canlynol:</p>
                            <ul>
                                <li>Monitro sut mae ein cwsmeriaid yn defnyddio'r wefan, sy'n ein helpu i wneud newidiadau i wella eich profiad ar y wefan.</li>
                                <li>Gwneud yn siŵr bod eich cynnydd drwy ein gemau wedi'i gofnodi, felly does dim rhaid i chi eu gorffen i gyd ar yr un pryd.</li>
                                <li>Casglu gwybodaeth ddemograffig ac ystadegau ymwelwyr sy'n ein galluogi i wella cynnwys a strwythur ein gwefan.</li>
                                <li>Casglu data ar dudalennau a welwyd.</li>
                                <li>Rydym hefyd yn rhannu gwybodaeth am eich defnydd o'r wefan hon gyda'n partneriaid cyfryngau cymdeithasol, hysbysebu a dadansoddi dibynadwy.</li>
                            </ul>
                            <p>Mae'r holl gwcis rydyn ni'n eu defnyddio yn casglu gwybodaeth yn ddienw (fel na allwn eich adnabod). Ceir rhagor o fanylion am sut yr ydym yn defnyddio gwybodaeth bersonol amdanoch yn ein <a href="https://www.dylansteachersquad.co.uk/cy/privacy-notice/">hysbysiad preifatrwydd.</a></p>
                            <h2>Pa gwcis ydyn ni'n eu defnyddio?</h2>
                            <p>Rydym ni’n defnyddio'r cwcis canlynol ar ein gwefan:</p>
                            <p><strong>Cwbl Angenrheidiol</strong> - Mae’r rhain yn gwcis y bernir eu bod yn angenrheidiol i weithrediad y wefan, fel y rhai sy'n mesur a'r rhai sy'n dosbarthu traffig gwefannau neu'r rhai sy'n caniatáu i chi fewngofnodi i rannau diogel.</p>
                            <p><strong>Perfformiad</strong> - Mae’r rhain yn gwcis sy'n olrhain pa mor dda y mae'r wefan yn gweithio, nodi unrhyw broblemau gyda thudalennau a sut rydych chi'n defnyddio ein gwefan.</p>
                            <div id={'manage-cookies2'}/>
                            <p><strong>Swyddogaethol</strong> - Mae’r rhain yn gwcis a ddefnyddir i ddarparu gwasanaethau neu i gofio gosodiadau er mwyn gwella eich profiad ar ein gwefan, fel dewisiadau iaith.</p>
                            <div id={'manage-cookies3'}/>
                            <p>Gallwch ddod o hyd i olwg fanylach ar ein cwcis ar waelod y dudalen hon. Gallwch reoli pob cwci drwy newid y gosodiadau yn eich porwr a gallwch optio allan o gwcis Dadansoddeg gan ddefnyddio'r togl uwch.</p>
                            <h2>Defnyddir cwcis ar y wefan hon</h2>
                            <script id='CookieDeclaration' src='https://consent.cookiebot.com/477a20a2-3536-44f4-8924-bb93ca22dec1/cd.js' type='text/javascript' async></script>
                            </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
}

export default CwcisPage;